(function() {

    var months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
    var monthsShort = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    var days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
    var daysShort = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];

    dmx.Formatters('string', {

        // getYear():Number
        getYear: function(str) {
            var date = dmx.parseDate(str);
            if (!validDate(date)) return undefined;
            return date.getFullYear();
        },

        // getMonth():Number
        getMonth: function(str) {
            var date = dmx.parseDate(str);
            if (!validDate(date)) return undefined;
            return date.getMonth() + 1;
        },

        // getDate():Number
        getDate: function(str) {
            var date = dmx.parseDate(str);
            if (!validDate(date)) return undefined;
            return date.getDate();
        },

        // getDay():Number
        getDay: function(str) {
            var date = dmx.parseDate(str);
            if (!validDate(date)) return undefined;
            return date.getDay();
        },

        // addYears(n:Number):String
        addYears: function(str, n) {
            var date = dmx.parseDate(str);
            if (!validDate(date)) return undefined;
            if (isNumber(n)) date.setFullYear(date.getFullYear() + n);
            return formatDate(date);
        },

        // addMonths(n:Number):String
        addMonths: function(str, n) {
            var date = dmx.parseDate(str);
            if (!validDate(date)) return undefined;
            if (isNumber(n)) date.setMonth(date.getMonth() + n);
            return formatDate(date);
        },

        // addWeeks(n:Number):String
        addWeeks: function(str, n) {
            var date = dmx.parseDate(str);
            if (!validDate(date)) return undefined;
            if (isNumber(n)) date.setDate(date.getDate() + (n * 7));
            return formatDate(date);
        },

        // addDays(n:Number):String
        addDays: function(str, n) {
            var date = dmx.parseDate(str);
            if (!validDate(date)) return undefined;
            if (isNumber(n)) date.setDate(date.getDate() + n);
            return formatDate(date);
        },

        // addHours(n:Number):String
        addHours: function(str, n) {
            var date = dmx.parseDate(str);
            if (!validDate(date)) return undefined;
            if (isNumber(n)) date.setHours(date.getHours() + n);
            return formatDate(date);
        },

        // addMinutes(n:Number):String
        addMinutes: function(str, n) {
            var date = dmx.parseDate(str);
            if (!validDate(date)) return undefined;
            if (isNumber(n)) date.setMinutes(date.getMinutes() + n);
            return formatDate(date);
        },

        // addSeconds(n:Number):String
        addSeconds: function(str, n) {
            var date = dmx.parseDate(str);
            if (!validDate(date)) return undefined;
            if (isNumber(n)) date.setSeconds(date.getSeconds() + n);
            return formatDate(date);
        },

        // addMilliseconds(n:Number):String
        addMilliseconds: function(str, n) {
            var date = dmx.parseDate(str);
            if (!validDate(date)) return undefined;
            if (isNumber(n)) date.setMilliseconds(date.getMilliseconds() + n);
            return formatDate(date);
        },

        // yearsUntil(date:String):Number
        yearsUntil: function(str1, str2) {
            var date1 = dmx.parseDate(str1);
            var date2 = dmx.parseDate(str2);
            if (!validDate(date1) || !validDate(date2)) return undefined;
            return date2.getFullYear() - date1.getFullYear();
        },

        // monthsUntil(date:String):Number
        monthsUntil: function(str1, str2) {
            var date1 = dmx.parseDate(str1);
            var date2 = dmx.parseDate(str2);
            if (!validDate(date1) || !validDate(date2)) return undefined;
            return (date2.getFullYear() * 12 + date2.getMonth()) - (date1.getFullYear() * 12 + date1.getMonth());
        },

        // weeksUntil(date:String):String
        weeksUntil: function(str1, str2) {
            var date1 = dmx.parseDate(str1);
            var date2 = dmx.parseDate(str2);
            if (!validDate(date1) || !validDate(date2)) return undefined;
            return Math.floor((date2 - date1) / 604800000); // 7 * 24 * 60 * 60 * 1000
        },

        // daysUntil(date:String):Number
        daysUntil: function(str1, str2) {
            var date1 = dmx.parseDate(str1);
            var date2 = dmx.parseDate(str2);
            if (!validDate(date1) || !validDate(date2)) return undefined;
            resetTime(date1);
            resetTime(date2);
            return Math.round((date2 - date1) / 86400000); // 24 * 60 * 60 * 1000
        },

        // hoursUntil(date:String):Number
        hoursUntil: function(str1, str2) {
            var date1 = dmx.parseDate(str1);
            var date2 = dmx.parseDate(str2);
            if (!validDate(date1) || !validDate(date2)) return undefined;
            return Math.floor((date2 - date1) / 3600000); // 60 * 60 * 1000
        },

        // minutesUntil(date:String):Number
        minutesUntil: function(str1, str2) {
            var date1 = dmx.parseDate(str1);
            var date2 = dmx.parseDate(str2);
            if (!validDate(date1) || !validDate(date2)) return undefined;
            return Math.floor((date2 - date1) / 60000); // 60 * 1000
        },

        // secondsUntil(date:String):Number
        secondsUntil: function(str1, str2) {
            var date1 = dmx.parseDate(str1);
            var date2 = dmx.parseDate(str2);
            if (!validDate(date1) || !validDate(date2)) return undefined;
            return Math.floor((date2 - date1) / 1000);
        },

        // millisecondsUntil(date:String):Number
        millisecondsUntil: function(str1, str2) {
            var date1 = dmx.parseDate(str1);
            var date2 = dmx.parseDate(str2);
            if (!validDate(date1) || !validDate(date2)) return undefined;
            return date2 - date1;
        },

        // timeUntil(date:String, incHours:Boolean):String
        timeUntil: function(str1, str2, incHours) {
            var date1 = dmx.parseDate(str1);
            var date2 = dmx.parseDate(str2);
            if (!validDate(date1) || !validDate(date2)) return undefined;
            var seconds = Math.floor((date2 - date1) / 1000);
            var minutes = Math.floor(seconds / 60);
            var hours = Math.floor(minutes / 60);
            seconds -= minutes * 60;
            if (incHours) {
                minutes -= hours * 60;
                return hours + ':' + pad(2, minutes) + ':' + pad(2, seconds);
            }
            return minutes + ':' + pad(2, seconds);
        },

        // formatDate(format:String):String
        formatDate: function(str, format) {
            var date = dmx.parseDate(str);
            if (!validDate(date)) return undefined;
            var year = date.getFullYear();
            var month = date.getMonth();
            var day = date.getDate();
            var weekday = date.getDay();
            var hours = date.getHours();
            var minutes = date.getMinutes();
            var seconds = date.getSeconds();
            return format.replace(/([yMdHhmsaA])(\1+)?/g, function(part) {
                switch (part) {
                    case 'yyyy': return pad(4, year);
                    case 'yy': return pad(2, year);
                    case 'y': return year;
                    case 'MMMM': return months[month];
                    case 'MMM': return monthsShort[month];
                    case 'MM': return pad(2, month + 1);
                    case 'M': return month + 1;
                    case 'dddd': return days[weekday];
                    case 'ddd': return daysShort[weekday];
                    case 'dd': return pad(2, day);
                    case 'd': return day;
                    case 'HH': return pad(2, hours);
                    case 'H': return hours;
                    case 'hh': return pad(2, (hours % 12) || 12);
                    case 'h': return (hours % 12) || 12;
                    case 'mm': return pad(2, minutes);
                    case 'm': return minutes;
                    case 'ss': return pad(2, seconds);
                    case 's': return seconds;
                    case 'a': return hours < 12 ? 'am' : 'pm';
                    case 'A': return hours < 12 ? 'AM' : 'PM';
                }
                return part;
            });
        },

    });

    function pad(s, n) {
        return ('0000' + n).slice(-s);
    }

    function formatDate(date) {
        return pad(4, date.getFullYear()) + '-' +
            pad(2, date.getMonth() + 1) + '-' +
            pad(2, date.getDate()) + ' ' +
            pad(2, date.getHours()) + ':' +
            pad(2, date.getMinutes()) + ':' +
            pad(2, date.getSeconds()) + '.' +
            pad(3, date.getMilliseconds());
    }

    function isNumber(n) {
        return Number(n) === n;
    }

    function resetTime(date) {
        date.setHours(0);
        date.setMinutes(0);
        date.setSeconds(0);
        date.setMilliseconds(0);
        return date;
    }

    function validDate(obj) {
        return obj && obj.toString() != 'Invalid Date';
    }

})();