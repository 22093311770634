(function() {

    dmx.Formatters('any', {

        // default(defaultValue:Any):Any
        default: function(obj, defaultValue) {
            return obj == null ? defaultValue : obj;
        },

        // toBool():Boolean
        toBool: function(obj) {
            return Boolean(obj);
        },

        // toDate():String
        toDate: function(obj) {
            var date = dmx.parseDate(obj);
            if (!validDate(date)) return undefined;
            return formatDate(date);
        },

        // toJSON():String
        toJSON: function(obj) {
            return JSON.stringify(obj);
        },
        
        // toNumber():Number
        toNumber: function(obj) {
            return Number(obj);
        },

        // toString():String
        toString: function(obj) {
            return String(obj);
        },

        // toTimestamp():Number
        toTimestamp: function(obj) {
            var date = dmx.parseDate(obj);
            if (!validDate(date)) return undefined;
            return Math.floor(date.getTime() / 1000);
        },

        // toUTCDate():String
        toUTCDate: function(obj) {
            var date = dmx.parseDate(obj);

            if (date.toString() == 'Invalid Date') {
                return undefined;
            }

            return date.toISOString();
        },

        // toISODate():String
        toISODate: function(obj) {
            var date = dmx.parseDate(obj);
            if (!validDate(date)) return undefined;
            return pad(4, date.getFullYear()) + '-' + pad(2, date.getMonth() + 1) + '-' + pad(2, date.getDate());
        },

        // toISOTime(incMilliseconds:Boolean):String
        toISOTime: function(obj, incMilliseconds) {
            var date = dmx.parseDate(obj);
            if (!validDate(date)) return undefined;
            return pad(2, date.getHours()) + ':' + pad(2, date.getMinutes()) + ':' + pad(2, date.getSeconds()) +
                   (incMilliseconds ? '.' + pad(3, date.getMilliseconds()) : '');
        },
        
    });

    function pad(s, n) {
        return ('0000' + n).slice(-s);
    }

    function formatDate(date) {
        return pad(4, date.getFullYear()) + '-' +
            pad(2, date.getMonth() + 1) + '-' +
            pad(2, date.getDate()) + ' ' +
            pad(2, date.getHours()) + ':' +
            pad(2, date.getMinutes()) + ':' +
            pad(2, date.getSeconds()) + '.' +
            pad(3, date.getMilliseconds());
    }

    function validDate(obj) {
        return obj && obj.toString() != 'Invalid Date';
    }

})();