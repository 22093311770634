(function() {

    dmx.Formatters('global', {

        // default(value:Any, defaultValue:Any):Any
        default: function(value, defaultValue) {
            return value == null ? defaultValue : value;
        },

        // bool(value:Any):Boolean
        bool: function(value) {
            return Boolean(value);
        },

        // string(value:Any):String
        string: function(value) {
            return String(value);
        },

        // number(value:Any):String
        number: function(value) {
            return Number(value);
        },

        // date(value:Any):String
        date: function(value) {
            var date = dmx.parseDate(value);
            if (!validDate(date)) return undefined;
            return formatDate(date);
        },

    });

    function pad(s, n) {
        return ('0000' + n).slice(-s);
    }

    function formatDate(date) {
        return pad(4, date.getFullYear()) + '-' +
            pad(2, date.getMonth() + 1) + '-' +
            pad(2, date.getDate()) + 'T' +
            pad(2, date.getHours()) + ':' +
            pad(2, date.getMinutes()) + ':' +
            pad(2, date.getSeconds()) + '.' +
            pad(3, date.getMilliseconds());
    }

    function validDate(obj) {
        console.warn('Invalid date object', obj);
        return obj && obj.toString() != 'Invalid Date';
    }

})();